.search-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 26px;

  .txt-search-main{
    width: 569px;
    border: 1px solid #e3e0e0;
    border-radius: 7px;
    padding: 16px;
    box-shadow: 3px 2px 9px 2px #80808033;
  }
  .search-icon{
    position: relative;
    left: -35px;
    i{
      font-size: 20px;
      color: var(--color-1st);
    }
  }
}

.ps-form.with-icon .form-control {
  padding: 0 15px;
  min-width: 255px;
  text-indent: 15px;
}

.loader {
  width: 215px;
  height: 220px;
  background:
    linear-gradient(0.25turn, transparent, #FFF, transparent),
    linear-gradient(#DDD, #DDD),
    linear-gradient(#DDD, #DDD),
    linear-gradient(#DDD, #DDD);
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 215px 220px, 215px 130px, 100px 15px, 150px 15px;
  background-position: -215px 0, 0 0, 15px 150px, 15px 180px;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  to {
    background-position: 215px 0, 0 0, 15px 150px, 15px 180px;
  }
}


// --------
.loader_2 {
  width: 215px;
  height: 215px;
  display: block;
  margin: auto;
  position: relative;
  background: #FFF;
  box-sizing: border-box;

  &::after {
    content: '';
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    top: 15px;
    left: 15px;
    position: absolute;
    background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5) 50%, transparent 100%),
    radial-gradient(circle 50px , #DDD 100%, transparent 0),
    linear-gradient(#DDD 16px, transparent 0),
    linear-gradient(#DDD 24px, transparent 0);
    background-repeat: no-repeat;
    background-size: 75px 175px, 100% 100px, 80% 16px, 80% 16px;
    background-position: -185px 0, center 10px, center 125px, center 155px;
    box-sizing: border-box;
    animation: animloader 1s linear infinite;
  }
}

@keyframes animloader {
  to {
    background-position: 185px 0, center 10px, center 125px, center 155px;
  }
}

// --------
.loader_3 {
  width: 320px;
  height: 150px;
  margin: auto;
  display: block;
  position: relative;
  background: #FFF;
  box-sizing: border-box;

  &::after {
    content: '';
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    top: 15px;
    left: 15px;
    position: absolute;
    background-image: linear-gradient(100deg, transparent, rgba(255, 255, 255, 0.5) 50%, transparent 80%), linear-gradient(#DDD 56px, transparent 0), linear-gradient(#DDD 24px, transparent 0), linear-gradient(#DDD 18px, transparent 0), linear-gradient(#DDD 66px, transparent 0);
    background-repeat: no-repeat;
    background-size: 75px 130px, 55px 56px, 160px 30px, 260px 20px, 290px 56px;
    background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
    box-sizing: border-box;
    animation: animloader_3 1s linear infinite;
  }
}


@keyframes animloader_3 {
  0% {
    background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  }
  100% {
    background-position: 150% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  }
}

.bs-datepicker-container{
  padding: 0 !important;
}


.required-star{
  color: #a60202;
  position: relative;
  left: 3px;
  top: -3px;
}

.visually-hidden {
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */
  clip: rect(1px, 1px, 1px, 1px); /*maybe deprecated but we need to support legacy browsers */
  clip-path: inset(50%); /*modern browsers, clip-path works inwards from each corner*/
  white-space: nowrap; /* added line to stop words getting smushed together (as they go onto seperate lines and some screen readers do not understand line feeds as a space */
}


.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.2), 0px 1px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}


element.style {
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.2), 0px 1px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.mat-card {
   border-radius: 13px !important;
 }

.form-control{
  height: 40px;
}

.info-box{
    padding: 15px 29px;
    border-radius: 10px;
  .main-title{
        color: var(--color-heading);
  }
  .sub-title{
    font-size: 1.8rem;
    .info-link{
      color: darkgreen;
    }
  }
}
.tooltip-icon{
  width: 15px;
  cursor: pointer;
}
.tooltip-inner{
  font-size: 1.5rem;
  max-width: 300px;
}


.toast-container .toast{
  font-size: 1.5rem;
}

.bolig-xl{
  .custom-fill-bg{
    .fa-user{
      font-size: 7rem;
      color: white;
    }

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bolig-content{
    min-height: 145px;
    background: white;

    .ps-panel__heading{
      font-size: 2.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .short-description{
      max-height: 3rem;
    }
  }
}

.add-new-container{
  border: 2px dashed #bebebe;
  border-radius: 1.5rem;
  padding: 4rem;
  display: flex;
  align-items: center;
  i{
    font-size: 3rem;
    color: #2178BE;
    margin-right: 2rem;
  }
}


.flex-space-between-center{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-list-container{
  background: white;
  border: 1px solid #dadada;
  border-radius: 1rem;
  padding: 0.8rem;
  box-shadow: 1px 1px 3px 0px #cbc4c4;
  .icon-container{
     i{
       font-size: 2rem;
       padding: 2rem;
       background: #ebf0ff;
       border-radius: 1rem;
     }
  }

  .item-details-container{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;

    .txt-lbl{}
    .dt-lbl{
      color: #9a9797;
      font-size: 1.4rem;
    }
  }
  .item-main-wrapper{
    display: flex;
  }

  .item-links-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.img-cover{
  overflow: hidden;
  border-radius: 1rem;
  border: 1px solid #dedddd;

}

button.ps-btn.btn-outline-danger {
  color: #dc3545 !important;
  border-color: #dc3545 !important;
  border: 2px solid;
  background: white;
  &:hover, &:active{
   background-color: #fdd9e07a;
 }
}

.disabled-menu{
  a{
    cursor: auto;
    color: grey !important;
  }
}
.nabonett-site .ps-dashboard .ps-dashboard__sidebar .menu--single .disabled-menu a i{
  color: grey !important;
}

h1.pg-heading{
  font-size: 24px;
}
.membership-card{
  width: 45rem;
  height: 27.1rem;
  background-color: #9ac7ca !important;
  font-family: 'Open Sans', sans-serif;

  &.bg-white{
    background-color: white !important;
  }
  .membership-card-text{
    font-family: 'Open Sans', sans-serif;
  }
 // box-shadow: 2px 2px 4px 5px rgb(84 13 202 / 32%), 0px 1px 4px 0px rgb(77 16 139 / 71%), 0px 1px 1px 0px rgb(63 35 188 / 26%) !important;
  .logo-container{
    display: inline-block;
    background: white;
    padding: 1rem;
    border-radius: 0 0 15px 15px;
    margin-right: 1rem;
  }
}

.admin-bg{
  box-shadow: 2px 2px 4px 5px rgba(84, 13, 202, 0.32), 0px 1px 4px 0px rgba(77, 16, 139, 0.71), 0px 1px 1px 0px rgba(63, 35, 188, 0.26) !important
}

.admin-lbl{
  color: purple;
}

fieldset.classic-style {
  border: 1px solid #ededed !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  legend{
    font-size: 1em !important;

    text-align: left !important;
    width:inherit; /* Or auto */
    padding:0 10px; /* To give a bit of padding on the left and right */
    border-bottom:none;
    color: #838383;
  }
}
.benefit-card{
  width: 22rem;
  max-width: 22rem;
  text-align: center;
  .link{
    display: flex;
    justify-content: center;
  }
  .custom-fill-bg{
    min-height: 6rem;
  }
}

.currency-container{
  display: flex;
  flex: 1 1 auto!important;
  align-items: center;
  .currency-txt{
    position: relative;
    left: -2.5rem;
    color: darkgrey;
  }
  button{
    margin-left: 1rem;
  }
}

.currency-label-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bg-light-blue{
  background: #E6F5FA;
}

.txt-bold-grey{
  font-weight: bold;
  color: dimgrey;
}

.btn-outline-info{
  color: #1780b8 !important;
  border-color: #1780b8 !important;
  background: white !important;
  border: 0.1em solid #1780b8 !important;
}

.btn-outline-grey{
  color: #5d5e5e !important;
  border-color: #5d5e5e !important;
  background: white !important;
  border: 0.1em solid #5d5e5e !important;
}

.flex-align-center{
  display: flex;
  align-items: center;
}


.flex-justify-center{
  display: flex;
  justify-content: center;
}

.flex-justify-right{
  display: flex;
  justify-content: right;
}

.logo-column{
  width: 9rem !important;
  //padding: 1rem;
}


.menu--mobile{
  li{
      &.active{
        background-color: #2178be24;
        color: #2178BE;
      }
    a{
      margin-left: 1rem;
      padding: 15px 20px 15px 0;
      line-height: 20px;
      font-weight: 500;
      display: flex;
      i{
        margin-right: 1rem;
      }
    }
    border-bottom: 1px solid var(--color-border);
}
}

.article-banner-wrapper{
  background-position: center;
  background-size: cover;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  height: 20rem;
}

.details-view{
  .article-banner-wrapper{
    height: auto;
  }

  .elem-paragraph{
    padding: 0;
  }
  .elem-header{
    margin-top: 3px;
    font-size: 2.2rem;
    font-family: "Jost", sans-serif;
    margin-bottom: 0;
  }
}

.ben-det-txt {
  min-height: 15.5rem;
  max-height: 15.5rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/* ------- TOOLTIP ----------*/
bs-tooltip-container{
  .tooltip-inner{
    background: #464444;
    .tooltip-content{
      text-align: left;
      font-size: 1.3rem;
      p{
        color: white;
        font-size: 1.3rem;
      }
    }
  }

}
.hint-container{
  height: 1px;
  position: relative;
  top: -26px;
}

.tooltip-inner {
  font-size: 1.5rem;
  max-width: 300px;
}

.st-password-view-wrap {
  width: 1px;
  a {
    position: relative;
    z-index: 111;
    left: -44px;
  }
}
