.benefit-card{
  .benefit-logo{
    // width: 15rem !important;
    padding: 1rem;
    max-height: 55px;
    max-width: 100%;
  }

  .benefit-teaser{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
    margin: 0rem 1rem;
    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.benefit-details-container{

  a{
    &:not(.custom-btn){
      color: #062fc4;
  }
  }
}
