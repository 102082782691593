
.header__account-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  padding: 8px;
  min-width: 180px;
  width: 100%;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-medium);
  z-index: 999;
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: all .4s ease;
}

.header__account-dropdown .menu--single > li > a {
  padding: 8px 16px;
  color: var(--color-heading);
  border-radius: var(--border-radius-medium);
}

.header__account-dropdown .menu--single > li > a:hover {
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.075);
}

.header__account > a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
  padding: 0 30px;
  color: var(--color-heading);
  border-radius: var(--border-radius-medium);
  font-weight: 500;
}

.header__account > a:hover {
  color: #fff;
  background-color: var(--color-1st);
}

.header__account i {
  margin-right: 16px;
  color: inherit;
}

.header__account.logged-in {
  position: relative;
}

.header__account.logged-in:hover .header__account-dropdown {
  visibility: visible;
  opacity: 1;
}

.header__submit-listing .ps-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
}

.header__submit-listing .ps-btn i {
  margin-left: 16px;
}

.header__actions {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.header--sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 10px 0;
  background-color: #fff;
  border-bottom: 1px solid var(--color-border);
}

.header--sticky .menu > li > a {
  padding-bottom: 16px;
}

.header--sticky .header__logo .ps-logo.light {
  display: none;
}

.header--desktop{
  .ps-logo img {
    max-height: 55px;
  }
  .ps-logo.light {
    display: none;
  }
  .header__right {
    display: flex;
    justify-content: flex-end;
  }
  .header__right .header__actions {
    margin-right: 10px;
  }
  .header__social-links {
    padding: 0 10px;
    border-right: 1px solid #d9dee8;
  }
  .header__social-links li {
    margin-right: 0;
  }
}


.header--desktop .header__social-links li a {
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 0;
}

.header--desktop .header__social-links li a i {
  color: var(--color-gray);
  font-size: 14px;
  transition: all 0.4s ease;
}

.header--desktop .header__social-links li a:hover {
  background-color: transparent;
}

.header--desktop .header__social-links li a:hover i {
  color: var(--color-2nd);
}

.header--desktop .ps-dropdown--fullscreen {
  position: static;
}

.header--desktop .header__categories-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: none;
  height: 40px;
  padding: 0 16px;
  min-width: 200px;
  line-height: 30px;
  text-transform: capitalize;
  border-radius: var(--border-radius-medium);
  color: #fff;
  background-color: var(--color-1st);
}

.header--desktop .header__categories-toggle i {
  margin-right: 6px;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  transition: all 0.4s ease;
}

.header--desktop .header__categories-toggle span {
  font-weight: 500;
  font-size: 15px;
  font-size: inherit;
  line-height: inherit;
  transition: all 0.4s ease;
}

.header--desktop .header__left .header__toggle-btn {
  display: none;
}

.header--desktop .header__top {
  padding: 20px 0;
}

.header--desktop .header__top > .container {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
}

.header--desktop .header__bottom {
  padding: 5px 0;
  /* border-top: 1px solid var(--color-border);
         border-bottom: 1px solid var(--color-border);*/
  background-color: var(--color-bg-gray);
}

.header--desktop .header__bottom .header__categories {
  margin-right: 20px;
}

.header--desktop .header__wrapper {
  position: relative;
}

@media screen and (max-width: 1199px) {
  .header--desktop {
    display: none;
  }
}

.header--fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  background-color: transparent;
}

.header--fixed.header--sticky {
  background-color: #fff;
  border-bottom: none;
  box-shadow: 1px 1px rgba(204, 204, 204, 0.25);
}

.header--transparent:not(.header--sticky) .menu--desktop > li > a {
  color: #fff;
}

.header--transparent:not(.header--sticky) .menu--desktop > li > a:after {
  background-color: #fff;
}

.header--transparent:not(.header--sticky) .header__submit-listing .ps-btn {
  background-color: rgba(255, 255, 255, 0.25);
  color: #ffffff;
}

.header--transparent:not(.header--sticky) .header__submit-listing .ps-btn:hover {
  background-color: var(--color-1st);
}

.header--transparent:not(.header--sticky) .header__account a {
  color: #ffffff;
}

.header--transparent:not(.header--sticky) .ps-logo:not(.light) {
  display: none;
}

.header--transparent:not(.header--sticky) .ps-logo.light {
  display: block;
}

.header--one .header__left {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.header--one .header__left .ps-logo {
  margin-right: 145px;
}

.header--one .header__actions .header__account {
  margin-right: 10px;
}

.header--one .header__wrapper {
  display: grid;
  grid-template-columns: 3fr 1.5fr;
  border-bottom: 1px solid var(--color-border);
  padding-top: 25px;
}

.header--one.header--sticky .header__account a {
  height: 46px;
}

.header--one.header--sticky .header__submit-listing .ps-btn {
  height: 46px;
}

.header--one.header--sticky .header__left, .header--one.header--sticky .header__right {
  align-items: center;
}

.header--one.header--sticky .header__wrapper {
  padding: 0;
  border-bottom: none;
}

@media screen and (max-width: 1440px) {
  .header--one .header__wrapper {
    grid-template-columns: 5fr 2fr;
  }
}

.header--mobile {
  position: relative;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 0 none;
  padding: 20px 16px;
  background-color: #fff;
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  border-bottom: 1px solid var(--color-border);
}

.header--mobile .ps-logo {
  line-height: 20px;
}

.header--mobile .ps-logo img {
  max-height: 30px;
}

.header--mobile .header__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header--mobile .header__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header--mobile.header--sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  padding: 10px 16px;
  border-bottom: 1px solid var(--color-gray);
}

@media (min-width: 1200px) {
  .header--mobile {
    display: none;
  }
}

@media screen and (max-width: 1199px) {
  .sidemenu--desktop{
    display: none;
  }

  .custom-account-wrapper .account-content-wrapper{
    margin-left: 10px !important;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

