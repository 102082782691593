.multiselect-dropdown .dropdown-btn .selected-item {
  max-width: 170px !important;
  margin-bottom: 2px;
}


.ownership-multi-select{
  .multiselect-dropdown .dropdown-btn .selected-item {
    max-width: 300px !important;
  }

}


.input-with-search{
  display: flex;
  i{
    position: relative;
    left: -24px;
    top: 8px;
  }
}
