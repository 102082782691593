@media screen and (min-width: 901px) {
  .ps-page--single-property.custom-style {

    .desktop-content{ display: block}
    .mobile-content{ display: none}



    .button-wrapper {
      display: flex;
      justify-content: center;

      .btn-small {
        width: 173px;
      }
    }

    .text-content{
      h4{
        margin-bottom: 0;

      }
    }

    .item-type {
      font-weight: 425;
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      letter-spacing: -0.011em;
    }

    .item-title {
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: #000000;
    }

    .item-pricing {
      font-weight: 395;
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      letter-spacing: -0.011em;

      color: #000000;
    }


    .stepper-container {
      display: flex;

      .ps-block--agent {
        border: none;

        .ps-block__content {
          padding-bottom: 0px;
        }
      }

      li {
        width: 25%;
        text-align: center;
        line-height: 2em;
        background: white;
        display: inline-block;
        color: white;
        position: relative;
        border: 1px solid var(--color-border);
        border-radius: var(--border-radius-medium);
        margin: 0 40px;

      }

      li::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -8em;
        width: 11em;
        height: 0.2em;
        background: #6FB844;
        z-index: -1;
      }

      li::after {
        content: "";
        position: absolute;
        width: 0.8em;
        height: 0.8em;
        text-align: center;
        line-height: 2em;
        border-radius: 1em;
        background: #6FB844;
        top: calc(50% - 0.3em);
        left: calc(100% + 4.6em);
        z-index: 2;
      }


      li:first-child::before {
        display: none;
      }

      li:first-child {
        margin-left: 0;
      }

      li:last-child {
        margin-right: 0
      }

      li:last-child::after {
        display: none;
      }
    }



    .green-highlight{
      color:#2C6740;
    }

  }
}
.custom-details {

  .val-highlighted {
    .val {
      font-size: 16px;
      font-weight: bold;

      &.extra-large {
        font-size: 24px;
      }
    }

  }

  .card-content{
    padding: 20px 20px 0px 20px;
  }

  .row {
    line-height: 35px;

    .title {
      font-weight: bold;
    }
  }
}
.content-wrapper{ height: 100%;


  &.small-card{
    display: flex;
    padding: 15px;
    min-height: 150px;
    .img-wrapper{
      overflow: hidden;
      text-align: center;
      width: 135px;
      background-size: cover;
      background-position: center;
      border-radius: 3px;
      img{
        width: 100px;
      }
    }
    .title{
      color: #2C6740;
    }
  }
}
.back-link {

  color: #2C6740;

  &:hover, a:hover {
    color: var(--color-1st);
  }
}
.ps-page--single-property.custom-style {
  .ps-page__header {
    padding-top: 20px;
    padding-bottom: 20px;


  }

  .content-wrapper {
    overflow: hidden;
    //box-shadow: 0px 0px 3px 1px #d8d8d8;
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-medium);

    .content-with-spacing {
      padding: 20px;
    }
  }
}

@media screen and (max-width: 1200px){
  .stepper-container {
    li {
      margin: 0 13px !important;
      .ps-btn.btn-small {
        height: auto;
       min-height: 40px;
      }
    }
    li::after {
      left: calc(100% + 1.2em) !important;
    }
  }
}
@media screen and (max-width: 900px){
  .stepper-container {
    padding: 0 70px !important;

    .desktop-content{ display: none}
    .mobile-content{ display: block}

    .ps-block--agent {
      border: none;

      .ps-block__content {
        text-align: left;
        min-height: 125px;
        padding-bottom: 0px;

        p{
          font-size: 1.9rem;
        }
      }
    }

    li {
      width: 100%;
      text-align: center;
      line-height: 2em;
      background: white;
      display: inline-block;
      color: white;
      position: relative;
      border: 1px solid #6FB844;
      border-radius: 16px;
      margin-bottom: 35px !important;

    }

    li::before {
      content: "";
      position: absolute;
      top: -10%;
      left: -6%;
      width: 9em;
      height: 0.1999999999999993em;
      background: #6FB844;
      z-index: -1;
      transform: rotate(90deg);
    }

    li::after {
      content: "";
      position: absolute;
      width: 0.8em;
      height: 0.8em;
      text-align: center;
      line-height: 2em;
      border-radius: 1em;
      background: #6FB844;
      top: calc(100% + 1.8em);
      left: 6% !important;
      z-index: 2;
    }


    li:first-child::before {
      display: none;
    }

    li:first-child {
      margin-left: 0;
    }

    li:last-child {
      margin-right: 0;
      margin-bottom: 0px !important;
    }

    li:last-child::after {
      display: none;
    }
  }

  .map-container{
    min-height: 30em;

  }
}

@media screen and (max-width: 768px){
   .stepper-container {
    padding: 0 !important;
    //li::before {
    //  left: 31%;
    //}

     li::after{
       left:3.45rem !important ;
     }

  }

  //.map-container{
  //  min-height: 30em;
  //
  //}
}

@media screen and (max-width: 575px){
  .stepper-container {

    li::before {
      left: -3.1rem;
    }

    li::after{
      left:3.45rem !important ;
    }

  }

  //.map-container{
  //  min-height: 30em;
  //
  //}
}
.ps-btn {
  &.btn-small {
    height: 33px;
    font-size: 15px;

  }
}


.custom-card-1{
  overflow: hidden;
  .custom-fill-bg{
    background-color: #EEF5E9;
    display: flex;
  }

  .ps-panel__heading{
    color: var(--color-1st);
  }
}

.semi-dark{
  color: dimgrey;
}


button:disabled,
button[disabled]{
  border: 1px solid #999999 !important;
  background-color: #D9D9D9 !important;
  color: #444444;

  &:hover{
    border: 1px solid #999999 !important;
    background-color: #D9D9D9 !important;
    color: #444444 !important;
    cursor: inherit;
  }
}

.flex-space-between{
  display: flex;
  justify-content: space-between;
}
.tbl-row-bordered{
  thead, tr{
    height: 4.5rem;
    border-bottom: 1px solid #e5e5e5;
  }
}

.cursor-pointer{
  cursor: pointer;
}
