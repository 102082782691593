.error-wrapper{
  overflow: hidden;
  margin-bottom: 0px;
  padding-left: 0;
  .error-list{
    color: red;
    text-align: right;
    list-style: none;
    font-size: 1rem;
    font-weight: 600;
    line-height: 15px;
  }

  &.text-left{
    .error-list{
      text-align: left;
    }
  }

}

.required-ctrl{
  color: #f70000;
}

.has-error{
  input, textarea, select{
    border: 1px solid #ffb1b1 !important;
  }
}


body{
  #success{
    color:#438702
  }

  #success, #error {
    font-size: 0.8rem;
  }
}
