@media screen and (max-width: 579px) {
  .membership__cards {
    flex-direction: column;
    gap: 25px;
  }
  .membership__info {
    margin-top: 12px !important;
  }
  .resp-align-horz{
    display: flex;
    .membership-card-text{
      margin-right: 1rem !important;
    }
    .resp-fixed-width{
      min-width: 50%;
    }
  }
}
@media screen and (min-width: 768px) {

}

@media screen and (min-width: 1200px) {

}

@media screen and (min-width: 1366px) {
  .membership-card{  max-width: 450px;}
}



@media screen and (max-width: 480px) {
  
  .membership-card{ max-width: 100%;}
  .tab-content{
    margin-bottom: 5rem;
  }
  .ps-panel__top{
    margin-top: 2rem;
  }
  .modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0,105px);
  }
  .ps-drawer{
    .fa-solid{
      margin-right: 1rem;
    }
  }
  .nabonett-site .restrict-width{
    padding: 0px;
  }

  .table-wrapper .custom-theme th, .table-wrapper .custom-theme td {
    padding: 0.4rem;

  }

  .responsive-auto-scroll{
    overflow: scroll;

    .tbl-row-bordered{
      th, td{
        border: 1px solid #eaeaea;
        text-align: center;
      }
    }
  }
}
