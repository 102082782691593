.modal-content-wrapper{
  .modal-header.bg-info{
    background: radial-gradient(759px at 14% 22.3%, hsla(148, 100%, 10%, 1) 0%, rgb(15, 164, 102) 90%);
    .modal-title{
      color: white;
    }
  }

  .close {
    color: #fff;
    opacity: 1;
  }
}
.modal-content {
  border: 0px ;
  border-radius: 0.9rem;
  overflow: hidden;
}
@media (min-width: 576px){
  .modal-dialog {
    max-width: 500px;
    margin-top: 7rem;
  }
}



@media (min-width: 992px) {

  .modal-1 {
    max-width: 100px;
  }
  .modal-2 {
    max-width: 200px;
  }
  .modal-3 {
    max-width: 300px;
  }
  .modal-4 {
    max-width: 400px;
  }
  .modal-5 {
    max-width: 500px;
  }
  .modal-6 {
    max-width: 600px;
  }
  .modal-7 {
    max-width: 700px;
  }
  .modal-8 {
    max-width: 800px;
  }
  .modal-9 {
    max-width: 900px;
  }

  .modal-lg {
    max-width: 800px;

  }
  .modal-xlg {
    max-width: 1000px;
  }

  .modal-1300 {
    max-width: 1300px;
  }
  .modal-mid {
    max-width: 575px;

  }
}



