

.table-wrapper{
  border:  0.5px solid #6FB844;
  border-radius: 0.6em;
  overflow: hidden;
  .custom-theme{
    width: 100%;
    border-collapse: collapse;
    label{ margin:0}
    th, td{
      padding: 0.8em;
      background: #fff;
      border-bottom: 1px solid #d3d3d3;

      &.collapsable{
        background: #cadaf1;

        padding: 0.5rem;
        cursor: pointer;

        &.expanded{
          background: #e7f0fd;
        }
        .grouped-container{
          display: flex;
          justify-content: space-between;
          .group-title{
            font-weight: bold;
          }
        }
      }
    }

    th{
      background-color: #EEF5E9;
      font-weight: bold;

      .mat-checkbox-label{
        font-weight: bold;
      }
    }

    tbody > tr:last-child td{
      border-bottom: 0px;
    }
  }
}
