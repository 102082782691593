$sideBarWidth: 300px;

.custom-account-wrapper {
  .ps-dashboard {
    display: inherit;

    .ps-dashboard__sidebar {
      background: none;
      padding-left: 0;

      .menu--single a {

      }
    }
  }

  .sticky-sidebar {
    width: $sideBarWidth;
    border-right: 1px solid #FFFFFF;
    box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
    height: 100%;
    top: 0px;

    .sidebar-footer {
      position: fixed;
      bottom: 0px;
      background: white;
      width: 280px;

      .ps-block--user-dashboard {
        padding-top: 20px;

        .ps-block__thumbnail {
          img {
            border-radius: 50%;
            border: 1px solid #ecebeb;
          }
        }
      }

      .section-sep {
        margin: 0px 20px 0px 0px;
      }

      .lnk-logout {
        display: flex;
        width: $sideBarWidth;
        align-items: center;
      }
    }

  }

  .account-content-wrapper {
    margin-left: $sideBarWidth;
  }

  .tab-content {
    padding: 20px;
  }

  .pg-heading {
    margin-left: 5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .photo-container {
    min-width: 13em;

    .ps-block__thumbnail {
      display: flex;
      justify-content: center;

      img {
        width: 120px;
        border-radius: 50%;
        border: 1px solid #ecebeb;
      }
    }
  }

  .profile-view {
    display: flex;
    flex-direction: column;

  }
}

.custom-frm {
  //padding-right: 40px;
  //max-width: 50em;

  .row {
    line-height: 4em;
    .frm-label, .frm-value, .normal-height{
      line-height: normal;
    }
  }

  .frm-label {
    display: flex;
    justify-content: space-between;
    .lbl-title{
      color: grey;
    }
    .action-link{
      color:darkgreen;
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
    }
  }

  .frm-value {
    font-weight: bold;
  }

  .bottom-border {
    border-bottom: 1px solid #dedbdb;
  }
}

.block-content-fixed{
  min-height: 115px;
}

.custom-ico {
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-size: contain;

  &.ico-overview {
    background-image: url("/assets/images/icons/ico-overview.svg");
  }

  &.ico-home {
    background-image: url("/assets/images/icons/ico-home.svg");
  }

  &.ico-collabs {
    background-image: url("/assets/images/icons/ico-collabs.svg");
  }

  &.ico-membership {
    background-image: url("/assets/images/icons/ico-membership.svg");
  }

  &.ico-profile {
    background-image: url("/assets/images/icons/ico-profile.svg");
  }

  &.ico-team {
    background-image: url("/assets/images/icons/ico-team.svg");
  }

  &.ico-logout {
    background-image: url("/assets/images/icons/ico-logout.svg");
  }

  &.ico-link {
    height: 16px;
    width: 16px;
    background-image: url("/assets/images/icons/ico-link.svg");
  }
}

.link-custom{
  display: flex;
  align-items: center;
  color: #2C6740;
}
.custom-btn {
  &.ps-btn {
    padding: 9px 15px;
    height: auto;
    &.ps-btn--with-opacity {
      background-color: white;
      border: 1px solid darkgreen;



      &:hover{
        color: #fff;
        background-color: var(--color-1st);
      }

      &.normalized{
        border: none;
        color: dimgrey;
        &:hover{
          color: #fff;
        }
      }
    }
  }
}

.restrict-width{
  max-width: 1000px;
}
