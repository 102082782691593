app-debug-panel {
  display: block;
  background-color: rgba(237, 119, 119, .9);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100037;
  margin-top: 46px;

  &.is-visible {
    bottom: 0;
    max-width: 40%;
    margin-bottom: 10px;
    /* margin-right: 56%;*/
  }

  input[type=checkbox] {
    display: none;
  }

  label {
    height: 16px;
    width: 100%;
    padding-left: 7px;
    padding-right: 7px;

    &:before {
      content: "Toggle Debug Bar";
      display: initial;
      cursor: pointer;
      background: none !important;
      color: White;

      font-family: inherit;
      font-weight: bold;
    }
  }

  input[type=checkbox]:checked + label:before {
    content: "Toggle Debug Bar";
  }

  div {
    display: none;
    overflow: auto;
  }

  &.is-visible div {
    display: block;
    height: calc(100% - 1.6em);
  }

  pre {
    font-size: 1em;
    padding: 20px;
    margin: 0;
    text-align: left;
    color: white;

  }
}
