
$col-blue: #2178BE;


.nabonett-site{
    background: #F9F9F9;

  .custom-account-wrapper .sticky-sidebar {
    background: white;
  }
  .ps-dashboard .ps-dashboard__sidebar .menu--single a i, .xl-link-icon{
      font-size: 2rem !important;
      color: $col-blue !important;
  }
  // ----------------
  .mat-tab-label{
    font-size: 17px;
    &.mat-tab-label-active{
      .mat-tab-label-content{
        color: $col-blue;
      }
    }
  }

  .link-custom {
    display: flex;
    align-items: center;
    color:  $col-blue;
  ;
  }

  .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar, .ps-footer--default, .header__account > a:hover{
    background-color: $col-blue;
    color: white;
  }

  .ps-btn, button.ps-btn{
    &.btn-primary, {
      background-color: $col-blue;
    }
  }
  button:disabled, button[disabled] {
     border: 0 !important;
    background-color: #22adec69 !important;
    color: #444444;
  }

  .btn-outline-danger{
    &:disabled, &[disabled]{
      background-color: rgba(211, 214, 215, 0.41) !important;
      color: #bdbbbb !important;
    }
  }

  .ps-btn:hover, .ps-btn:active, button.ps-btn:hover, button.ps-btn:active {
    color: hsl(206.56deg 95.32% 25.48%);
    background-color: #189cd27a;
  }
  .ps-dashboard .menu--single .active a, .ps-dashboard .menu--single a:hover {
    background-color: #2178be24;
  }
  .ps-dashboard .menu--single li{
    margin-bottom: 1px;

    a:hover{
      text-decoration: none !important;
    }
  }
  .ps-dashboard .menu--single .active a span , .lbl-title i, .custom-frm .frm-label .action-link, .col-blue, .header__account > a {
    color: $col-blue;
  }
  .ps-logo{
    font-size: 3rem;
  }

  .modal-content-wrapper .modal-header.bg-info {
    background-color: #2178BE !important;
    background: #2178BE !important;
  }


  .bg-light-blue{
    background-color: #E9F9FF;
  }

  .custom-card-1 {
    .custom-fill-bg {
      background-color: #e9f3f5;
    }
    .ps-panel__heading {
      color: #0a3260;
      font-size: 2rem;
    }
  }

  .restrict-width {
    max-width: 1200px;
  }


  .table-wrapper .custom-theme th{
    background-color: #e9f1f5;
  }
  .table-wrapper .custom-theme th, .table-wrapper .custom-theme td{

    border-bottom: 1px solid #d3d3d3;
  }

  .table-wrapper {
    border: 0.5px solid #4484b8;
  }

  .admin-card{
      background-color: #ebe9ff;
  }

  .status-icon-container{
    display: flex;
    justify-content: center;
    padding-top: 6rem;
    padding-bottom: 2rem;
    .status-big-icon{
      display: inline-block;
      border-radius: 60px;
      box-shadow: 0 0 2px #888;
      padding: 0.6em;
      font-size: 5rem;
      background-color: green;
      color: white;
    }
  }

  .type-abort{
    .status-icon-container{
      .status-big-icon{
        border-radius: 60px;
        padding: 0.6em 0.8em;
        background-color: #ed5a5a;
      }
    }
  }
  .type-error{
    .status-icon-container{
      .status-big-icon{
        border-radius: 60px;
        padding: 0.6em 1em;
        background-color: #ef6a2b;
      }
    }
  }
.btn-navigator{
  cursor: pointer;
  font-size: 2rem;
  &:hover{
    color: #2178BE;
    font-weight: bold;
  }

}
  //  ----------
}
